import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import Task from './task'; 

const Container = styled.div`
	margin: 8px;
	border: 1px solid lightgrey;
	border-radius: 2px;
	width: 400px;
	display: flex;
	flex-direction: column;
`;
const Title = styled.h3`
	padding: 8px;
`;
const TaskList = styled.div`
	padding 8px;
	background-color: ${props => (props.isDraggingOver ? '#ddd' : 'white')};
	flex-grow: 1;
	min-height: 100px;
`;


export default class Column extends React.Component {
	render () {
		return (
			<Container>
				<Title>{this.props.column.title}</Title>
				<Droppable droppableId={this.props.column.id}>
					{(provided, snapshot) => (
					<TaskList
						ref={provided.innerRef}
						isDraggingOver={snapshot.isDraggingOver}
						{...provided.droppableProps}
					>
						{this.props.tasks.map( (task,index) => <Task showNumber={this.props.column.ordered} key={task.id} task={task} index={index} />)}
						{provided.placeholder}
					</TaskList>
					)}
				</Droppable>
			</Container>
			);
	}
}