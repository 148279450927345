const initialData = {
	tasks: {
		'task-1' : { id: 'task-1', content: 'Aria Cafe'},
		'task-2' : { id: 'task-2', content: 'Beauty & Essex at Cosmopolitan'},
		'task-3' : { id: 'task-3', content: 'Café St. Louis at Paris'},
		'task-4' : { id: 'task-4', content: 'Cornerstone at Gold Coast' },
		'task-5' : { id: 'task-5', content: 'House of Blues Restaurant & Bar at Mandalay Bay' },
		'task-6' : { id: 'task-6', content: 'Jardin at the Venetian' },
		'task-7' : { id: 'task-7', content: 'Lavo Italian Restaurant at the Venetian' },
		'task-8' : { id: 'task-8', content: 'Nine Fine Irishmen at New York-New York' },
		'task-9' : { id: 'task-9', content: 'Steak House at Circus Circus ' },
	},
	columns: {
		'column-1': {
			id: 'column-1',
			title: 'Restaurants',
			ordered: false,
			taskIds: ['task-1', 'task-2', 'task-3', 'task-4','task-5','task-6','task-7','task-8','task-9'],
		},
		'column-2': {
			id: 'column-2',
			ordered: true,
			title: 'Ranking',
			taskIds: [],
		},
					
	},
	// Facilitate reording of the columns
	columnOrder: ['column-1','column-2'],
	user: null,
};

export default initialData;