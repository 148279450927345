import React from 'react';
import ReactDOM from 'react-dom';
import '@atlaskit/css-reset';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import initialData from './initial-data';
import Column from './column';
import FacebookLogin from 'react-facebook-login';


const Container = styled.div`
	display: flex;
`;
const AppContainer = styled.div``;
const ButtonContainer = styled.div`
	width: 100%;
	text-align: center;
`;
const BallotTitle = styled.h1`
	margin-left: 10px;
`;
class VoteButton extends React.Component {
	render () {
		return ( 
			<ButtonContainer>
				<button onClick={this.props.onClick}>Submit Vote</button> 
			</ButtonContainer>
			);
	};
};

class App extends React.Component {
	state = initialData;

	onDragEnd = result => {
		const { destination, source, draggableId} = result;
		if(!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId &&
			destination.index === source.index
			) {
			return;
		}

		const start = this.state.columns[source.droppableId];
		const finish = this.state.columns[destination.droppableId]; 

		if (start === finish) {
			const newTaskIds = Array.from(start.taskIds);

			newTaskIds.splice(source.index,1);
			newTaskIds.splice(destination.index,0,draggableId);
			const newColumn = { 
				...start,
				taskIds: newTaskIds,
			};
			const newState = {
				...this.state,
				columns: {
					...this.state.columns,
					[newColumn.id]: newColumn,
				},
			};

			this.setState(newState);
			return;
		}
		//Moving from one list to another
		const startTaskIds = Array.from(start.taskIds);
		startTaskIds.splice(source.index, 1);
		const newStart = {
			...start,
			taskIds: startTaskIds,
		};
		const finishTaskIds = Array.from(finish.taskIds);
		finishTaskIds.splice(destination.index, 0, draggableId);
		const newFinish = {
			...finish,
			taskIds: finishTaskIds,
		};

		const newState = {
			...this.state,
			columns: {
				...this.state.columns,
				[newStart.id]: newStart,
				[newFinish.id]: newFinish,
			},
		};
		this.setState(newState);

	}

	handleClick = () => {
		const taskIds = this.state.columns['column-2'].taskIds;
		let ballot = taskIds.map( taskId => taskId.split('-')[1]); 

		const form = document.createElement('form');
		form.method = 'post';
		form.action = 'https://vote.gnuyen.org/vote.php';
		const params = { 'name': this.state.user, 'ballot': JSON.stringify(ballot), };

		for (const key in params) {
			if (params.hasOwnProperty(key)) {
			  const hiddenField = document.createElement('input');
			  hiddenField.type = 'hidden';
			  hiddenField.name = key;
			  hiddenField.value = params[key];

			  form.appendChild(hiddenField);
			}
		}

		document.body.appendChild(form);
		form.submit();
	};

	responseFacebook = (response) => {
		console.log(response);
		if (typeof response.name !== 'undefined') {
			const newState = {
				...this.state,
				user: response.name,
			};
			this.setState(newState);
		}

	};
	componentClicked = () => {
		return true;
	}
	render() {
		let voteComponent = null;

		if (this.state.columns['column-2'].taskIds.length >=3) {
			voteComponent =<VoteButton onClick={this.handleClick} />;
		} else {
			voteComponent = <p />;
		}
		if (this.state.user === null) {
			return ( <FacebookLogin
    		appId="1894632380682639"
    		size="large"
    		autoLoad={true}
    		fields="name,email"
    		onClick={this.componentClicked}
   		 	callback={this.responseFacebook} />);
		} else {
			return (
				<AppContainer>
					<BallotTitle>Ballot for {this.state.user}</BallotTitle>
					<DragDropContext

						onDragEnd={this.onDragEnd}
					>
						<Container>
							{this.state.columnOrder.map((columnId)=> {
								const column = this.state.columns[columnId];
								const tasks = column.taskIds.map(taskId => this.state.tasks[taskId]);

								return <Column key={column.id} column={column} tasks={tasks} />;
							})}
						</Container>
					</DragDropContext>
					{voteComponent}
				</AppContainer>
			);
		}
	}
}
ReactDOM.render(<App />, document.getElementById('root'));
